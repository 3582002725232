<template>
  <GoogleMap :api-key="googleMapKey"
             style="width: 100%; height: 300px"
             :center="center"
             :zoom="15">
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  components: {GoogleMap, Marker},
  props: ['center'],
  data() {
    return {
      googleMapKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
    }
  }
}
</script>