<template>
  <div class="modal fade" id="login-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-apply-promoLabel">Login Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="loginSession">
          <table class="table table-bordered">
            <tr>
              <th>Logged At</th>
              <td>{{ loginSession.created_at }}</td>
              <th>Last Active At</th>
              <td>{{ loginSession.last_active_at }}</td>
              <th>Logout At</th>
              <td>{{ loginSession.logout_at }}</td>
            </tr>
            <tr>
              <th>IP</th>
              <td>{{ loginSession.ip }}</td>
              <th>Device</th>
              <td>{{ loginSession.device_name }}</td>
              <th>OS</th>
              <td>{{ loginSession.os }}</td>
            </tr>
            <tr>
              <th>Browser</th>
              <td>{{ loginSession.browser }}</td>
              <th>ISP</th>
              <td>{{ loginSession.isp }}</td>
              <th>Timezone</th>
              <td>{{ loginSession.timezone }}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{{ loginSession.country }}</td>
              <th>City</th>
              <td>{{ loginSession.city }}</td>
              <th>Zip</th>
              <td>{{ loginSession.zip }}</td>
            </tr>
          </table>

          <google-map v-if="mapCenter" :center="mapCenter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/google_maps/GoogleMap.vue";
export default {
  components: {GoogleMap},
  data() {
    return {
      modal: null,
      loginSession: null,
      mapCenter: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('login-details-modal'))
  },
  methods: {
    show(loginSession) {
      this.loginSession = loginSession;
      this.mapCenter = { lat: loginSession.lat, lng: loginSession.lon }
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>