<template>
  <page-title-component title="Users"></page-title-component>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table-component
                  :columns="columns"
                  :rows="users"
                  :sortable="sortable"
                  @sorting="sorting"
                  :pagination="pagination"
                  @search="search"
                  @pageChanged="pageChanged">
            <template v-slot:actions>
              <div class="row">
                <div class="col-4">
                  <select class="form-control form-control-sm"
                          @change="loadData"
                          v-model="queryParams.type">
                    <option value="">All User</option>
                    <option value="influencer">Collab License</option>
                    <option value="full">Full License</option>
                  </select>
                </div>
              </div>
            </template>

            <template v-slot:action="{row}">
              <a class="btn btn-sm btn-primary"
                 @click.prevent="openInNewTab({name: 'users_details', params: {id: row.id}})">View</a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import TableComponent from "@/components/TableComponent";
export default {
  components: {TableComponent, PageTitleComponent},
  data() {
    return {
      users: [],
      queryParams: {
        search: '',
        type: '',
        search_columns: '',
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          searchable: true,
        },
        {
          label: "Email",
          field: "email",
          sortable: true,
          searchable: true,
        },
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Last Login At",
          field: "last_login_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Last Activity At",
          field: "last_activity_at",
          sortable: true,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          class: ['no-wrap text-right'],
          slot: true
        },
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.users = [];

      this.axios.get('/users', {params: this.queryParams})
        .then((response) => {
          this.users = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>