<template>
  <page-title-component title="User Details"></page-title-component>

  <div class="row" v-if="user">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered">
            <tr>
              <th>Name</th>
              <td>{{ user.name }}</td>
              <th>Email</th>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <th>Stripe ID</th>
              <td>{{ user.stripe_id }}</td>
              <th>Signed Up At</th>
              <td>{{ user.created_at }}</td>
            </tr>
            <tr>
              <th>Current Plan</th>
              <td>{{ currentPlan }}</td>
              <th>Next Billing Date</th>
              <td>{{ user.next_billing }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3" v-if="user">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">Payment History</div>
        <div class="card-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Created At</th>
              <th>Service Period</th>
              <th>Amount</th>
              <th class="text-right">Invoice</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(invoice, i) in user.invoices" :key="'invoice_'+i">
              <td>{{ invoice.number }}</td>
              <td>{{ invoice.created }}</td>
              <td>{{ invoice.service_period }}</td>
              <td>{{ invoice.total }}</td>
              <td class="text-right">
                <a class="text-info" :href="invoice.pdf" target="_blank">Download</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">Login History</div>
        <div class="card-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>IP</th>
              <th>Logged At</th>
              <th>Last Active At</th>
              <th>Logout At</th>
              <th class="text-right">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item, i) in loginHistory" :key="'loginHistory_'+i">
              <td>{{ item.ip }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.last_active_at }}</td>
              <td>{{ item.logout_at }}</td>
              <td class="text-right">
                <a class="text-info"
                   @click.prevent="$refs.loginDetailsModal.show(item)">Details</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <login-details-modal ref="loginDetailsModal" />
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import LoginDetailsModal from "@/views/user/LoginDetailsModal.vue";

export default {
  components: {PageTitleComponent, LoginDetailsModal},
  data() {
    return {
      user: null,
      loginHistory: []
    }
  },
  computed: {
    currentPlan() {
      if (this.user.agency){
        return this.user.agency + ' (' + this.user.license_type + ') - ' + this.user.license
      } else if (this.user.product_name) {
        return this.user.product_name + ' ($' + this.user.price + ')';
      } else {
        return "";
      }
    }
  },
  created() {
    this.loadData();
    this.loadLoginHistory();
  },
  methods: {
    loadData() {
      this.axios.get('/users/' + this.$route.params.id)
          .then(res => {
            this.user = res.data.data;
          })
    },
    loadLoginHistory() {
      this.axios.get('/users/login-history/' + this.$route.params.id)
          .then(res => {
            this.loginHistory = res.data.data;
          })
    }
  }
}
</script>